import { Pipe, PipeTransform } from '@angular/core';
import {TimelineEvent} from "../components/timeline/timeline-event";

/**
 * Pipe filter for filtering the list of the objects based on the title property
 */
@Pipe({
  name: 'timelinefilter'
})
export class TimelinefilterPipe implements PipeTransform {

  transform(events: TimelineEvent[], term?: string): any {
    if( term === undefined) return events;
    if( !events) return [];
    return events.filter((event) => {
      return event.title.toLowerCase().includes(term.trim().toLowerCase());
    })
  }
}
