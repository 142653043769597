/**
 * The timeline supported model
 */
export interface TimelineEvent {
  title: string;
  body: string;
  footer?: string;
  importance: TimelineImportance;
  id: string;
  date: Date;
}

/**
 * Enumerable for the importance of each timeline event. Depending on the value,
 * we change the color of the event
 */
export enum TimelineImportance {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success'
}
