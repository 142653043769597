import {Component, ContentChild, Input, OnInit} from '@angular/core';
import {TimelineEvent} from './timeline-event';
import {
  KmbTimelineDateDirective,
  KmbTimelineHeaderDirective,
  KmbTimelineBodyDirective,
  KmbTimelineFooterDirective,
  KmbTimelineSearchInputDirective,
  KmbTimelineDateFilterDirective
} from './timeline.directive';
import {Observable} from 'rxjs';

@Component({
  selector: 'kmb-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() events: Observable<TimelineEvent[]>;
  @Input() updateFilter: (fromDate: Date, untilDate: Date) => void;
  @Input() timelineClass: string;
  @Input() timelineStyle: any;
  @Input() term: string;
  @Input() fromDate: Date;
  @Input() untilDate: Date;

  @ContentChild(KmbTimelineDateDirective) dateTemplate: KmbTimelineDateDirective;
  @ContentChild(KmbTimelineHeaderDirective) headerTemplate: KmbTimelineHeaderDirective;
  @ContentChild(KmbTimelineBodyDirective) bodyTemplate: KmbTimelineBodyDirective;
  @ContentChild(KmbTimelineFooterDirective) footerTemplate: KmbTimelineFooterDirective;
  @ContentChild(KmbTimelineSearchInputDirective) searchInputTemplate: KmbTimelineSearchInputDirective;
  @ContentChild(KmbTimelineDateFilterDirective) dateFilterTemplate: KmbTimelineDateFilterDirective;

  constructor() {
  }

  ngOnInit() {
  }

  trackById(index: number, item: TimelineEvent) {
    return item.id; // unique id corresponding to the item
  }

  filter(fromDate: string, untilDate: string) {
    this.updateFilter(fromDate ? new Date(fromDate) : null, untilDate ? new Date(untilDate): null);
  }
}
