import { Directive, TemplateRef } from '@angular/core';

/**
 * Structural directive for showing the date
 */
@Directive({ selector: 'ng-template[kmbTimelineDate]' })
export class KmbTimelineDateDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

/**
 * Structural directive for showing the header
 */
@Directive({ selector: 'ng-template[kmbTimelineHeader]' })
export class KmbTimelineHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

/**
 * Structural directive for showing the body
 */
@Directive({ selector: 'ng-template[kmbTimelineBody]' })
export class KmbTimelineBodyDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

/**
 * Structural directive for showing the footer
 */
@Directive({ selector: 'ng-template[kmbTimelineFooter]' })
export class KmbTimelineFooterDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

/**
 * Structural directive for showing the search input
 */
@Directive({ selector: 'ng-template[kmbTimelineSearchInput]' })
export class KmbTimelineSearchInputDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

/**
 * Structural directive for showing the date filter inputs
 */
@Directive({ selector: 'ng-template[kmbTimelineDateFilter]' })
export class KmbTimelineDateFilterDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}
