import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Http interceptor to add the jwt token to the Authorization Bearer header when making
 * the request. For now we are using hardcoded a generated jwt token
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = JSON.parse(localStorage.getItem('ngStorage-currentUser'));
    if (!currentUser) {
      localStorage.setItem('ngStorage-currentUser' , '{"email":"andreas.k@kissmybutton.gr","token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJmZjM1MzcxYy04MTM2LTQ0ZTUtYjVkYi1lOTdmZTFjMGE2MzYiLCJpYXQiOjE1NDIzNzE3MzAsImV4cCI6MTU0Mjk3NjUzMCwiY29udGV4dCI6eyJyb2xlIjpbInBhdGllbnQiXSwibGFuZyI6ImRlIn19.z21JBXMP6rvtowJeyAUi1CcA3SLTb8A6YvxZwi-SEdikPfKVhYwsholfpigg69WQ0JRk7bbzOPLlULDsIKeKxyb_EwqG37kGgjYo2odJAKUdaupAxRPWNS5nrcEU5FBXB3Vv7FIpJT1a9-9uawaryddePVU3kmgLobBBJKFnojzEbMpXozVeqrXuUXgH7GcqU_dBp7hElfH5XZYKFm3Cr9NnkPzk5frCKzTG1Az-u7R0Nb6dLI_oGNanXNQQEGyh073kMHB2PlXxrZWMm2Mo1LQPEQg0Rs3cOmROEBf7sGC0Bxw7kFm8p17QEaxF3FeZSS_Mxf2PYL7DaOBrw3XQqg"}');
      currentUser = JSON.parse(localStorage.getItem('ngStorage-currentUser'));
    }
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    return next.handle(request);
  }
}
