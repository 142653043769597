import {NgModule} from '@angular/core';
import {TimelineLibComponent} from './timeline-lib.component';
import {TimelineComponent} from './components/timeline/timeline.component';
import {BrowserModule} from '@angular/platform-browser';
import {
  KmbTimelineDateDirective,
  KmbTimelineHeaderDirective,
  KmbTimelineBodyDirective,
  KmbTimelineFooterDirective,
  KmbTimelineSearchInputDirective,
  KmbTimelineDateFilterDirective
} from './components/timeline/timeline.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { TimelinefilterPipe } from './pipes/timelinefilter.pipe';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    TimelineLibComponent,
    TimelineComponent,
    KmbTimelineDateDirective,
    KmbTimelineHeaderDirective,
    KmbTimelineBodyDirective,
    KmbTimelineFooterDirective,
    KmbTimelineSearchInputDirective,
    KmbTimelineDateFilterDirective,
    LoaderComponent,
    TimelinefilterPipe
  ],
  imports: [
    BrowserModule,
    FormsModule
  ],
  exports: [
    TimelineLibComponent,
    TimelineComponent,
    KmbTimelineDateDirective,
    KmbTimelineHeaderDirective,
    KmbTimelineBodyDirective,
    KmbTimelineFooterDirective,
    KmbTimelineSearchInputDirective,
    KmbTimelineDateFilterDirective,
  ]
})
export class TimelineLibModule {
}
