import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Diary, DiaryResultDto} from '../interfaces/dtos/DiaryResultDto';
import {TimelineEvent, TimelineImportance} from '../../../projects/timeline-lib/src/lib/components/timeline/timeline-event';
import {environment} from '../../environments/environment';

/**
 * Service for calling the diary API
 */
@Injectable({
  providedIn: 'root'
})
export class DiaryService {

  constructor(private http: HttpClient) { }

  /**
   * Get random color for the event importance of the timeline
   */
  private getRandomColor(): TimelineImportance {
    const keys = Object.keys(TimelineImportance);
    return TimelineImportance[keys[ keys.length * Math.random() << 0]];
  }
  /**
   * Get the diaries of the current logged in user
   * It accepts a from and to parameters as Date objects and converts them to (iso 8601 datetime) date string without the timezone
   * @param from The optional "from" date filter
   * @param until The optional "until" date filter
   * @returns A collection of diary objects
   */
  getDiaries = (from?: Date, until?: Date) => {
    let url = 'user/diary?';

    if (from) {
      const fromDateString = from.toISOString();
      url += `from=${fromDateString.substring(fromDateString.length - 1, 0)}&`;
    }
    if (until) {
      const untilDateString = until.toISOString();
      url += `until=${untilDateString.substring(untilDateString.length - 1, 0)}`;
    }

    return this.http.get<DiaryResultDto>(`${environment.apiUrl}/${url}`).pipe(map(res =>
      res.diaries.map( (diaryDetails: Diary, index: number): TimelineEvent => {
        return {
          title: `Title ${index}`,
          body: diaryDetails.diary.event.eventDescription,
          date: diaryDetails.timestamp,
          footer: '',
          id: diaryDetails.id,
          importance: this.getRandomColor()
        };
      }).sort((eventA: TimelineEvent, eventB: TimelineEvent) =>
        new Date(eventB.date).getTime() - new Date(eventA.date).getTime()
      )
    ));
  }
}
