import { Injectable } from '@angular/core';

/**
 * Authentication service. This is used only for logging the user out if we get a 403 forbidden error
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor() { }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('ngStorage-currentUser');
  }
}
