import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {DiaryService} from './services/diary.service';
import {TimelineEvent} from '../../projects/timeline-lib/src/lib/components/timeline/timeline-event';

/**
 * Main component of the application. This is used for including our library
 * We show 2 versions of the timeline just for showing the level of customization
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  events$: Observable<TimelineEvent[]>;
  eventsCustom$: Observable<TimelineEvent[]>;

  // for custom template we save the dates as well
  fromDate: Date;
  untilDate: Date;
  term: string;

  constructor(private diaryService: DiaryService) {
  }

  ngOnInit() {
    this.events$ = this.diaryService.getDiaries(new Date('02/02/2018'), new Date('02/02/2019'));
    this.eventsCustom$ = this.diaryService.getDiaries(new Date('02/02/2018'), new Date('02/02/2019'));
  }

  updateFilter = (fromDate: Date, untilDate: Date) => {
    this.events$ = this.diaryService.getDiaries(fromDate, untilDate);
  }

  updateFilterCustom = (fromDate: Date, untilDate: Date) => {
    this.eventsCustom$ = this.diaryService.getDiaries(fromDate, untilDate);
  }

  onDateChanged() {
    this.eventsCustom$ = this.diaryService.getDiaries(this.fromDate, this.untilDate);
  }

  updateTerm(event: any) {
    this.term = event.target.value
  }
}
